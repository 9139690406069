import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import queryString from "query-string";

let qs = queryString.parse(window.location.search);

if (qs.requestId && qs.sessionTopic) {
  document.write("Continue on smartphone.");
  window.close();
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
